import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { PageLogo } from '../components/PageLogo';

export function HomeScreen() {
  return (
    <Container component="main" maxWidth="md" sx={{mt: 5}}>
      <CssBaseline />
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <PageLogo />
        <Link sx={{ mt: 7 }} href="/forgot-password">Forgot Your Password?</Link>
        <Link sx={{ mt: 1 }} href="/contact-support">Need Support?</Link>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{mt: 7}}>
          <a href="https://apps.apple.com/us/app/smart-mine-activity-reporting/id1605841287">
            <Box
              component="img"
              sx={{
                height: 70,
                maxHeight: { xs: 50, md: 70 },
                mr: 3,
              }}
              alt="Get it on the App Store"
              src={process.env.PUBLIC_URL + '/apple_store.svg'}
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=edu.arizona.miningsh.smart&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <Box
              component="img"
              sx={{
                height: 70,
                maxHeight: { xs: 50, md: 70 },
              }}
              alt="Get it on Google Play"
              src={process.env.PUBLIC_URL + '/google_store.png'}
            />
          </a>
        </Box>
      </Box>
    </Container>
  );
}