import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

export function PageLogo(props) {
  let imageSx;

  if (props.size === 'small') {
    imageSx = {
      width: 200,
      height: 100,
    };
  } else {
    imageSx = {
      width: 300,
      height: 200,
      maxWidth: { xs: 250, md: 300 },
      maxHeight: { xs: 167, md: 200 },
    };
  }

  return (
    <>
      <Link href="/">
        <Box
          component="img"
          sx={imageSx}
          alt="S.M.A.R.T."
          src={process.env.PUBLIC_URL + '/logo_white.svg'}
        />
      </Link>
      <Typography sx={{ mt: 2, mb: props.mb ? props.mb : 1 }} component="h1" variant="h5">
        S.M.A.R.T.
      </Typography>
    </>
  );
}