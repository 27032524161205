import { ContactSupportScreen } from "./ContactSupportScreen";
import { RECAPTCHA_KEY } from "../Constants";
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export function ContactSupportWrapperScreen() {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={RECAPTCHA_KEY}>
            <ContactSupportScreen />
        </GoogleReCaptchaProvider>
    );
};