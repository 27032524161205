//export const API_BASE_URL = 'https://api.test.smartminesafety.com/api/v1/s/';
//export const API_BASE_URL = 'https://api.smartminesafety.com/api/v1/s/';
//export const API_BASE_URL = 'http://192.168.1.145:8090/';
//export const API_BASE_URL = 'http://localhost:8090/';
export const API_BASE_URL = 'https://api.smartminesafety.com/';
export const LAMBDA_BASE_URL = 'https://lambda.smartminesafety.com/';
export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const RECAPTCHA_KEY = '6LcuI6wkAAAAAO6yvgfG48krA9rwI60JkPlrxLuA';
export const ACTIVITY_TYPE = {
  FILING: 0,
  CALL: 1,
  REPAIR: 2,
  EVENT: 3,
  CITATION: 4,
};
export const USER_STATUS = {
  PENDING: {id: -1, name: 'Pending'},
  INACTIVE: {id: 0, name: 'Inactive'},
  ACTIVE: {id: 1, name: 'Active'},
};
export const USER_STATUSES = [
  USER_STATUS.PENDING, USER_STATUS.INACTIVE, USER_STATUS.ACTIVE
];
export const USER_ROLE = {
  USER: {id: 0, name: 'user'},
  ORG_ADMIN: {id: 1, name: 'org-admin'},
  SUPER_ADMIN: {id: 2, name: 'super-admin'},
};
export const USER_ROLES = [
  USER_ROLE.USER, USER_ROLE.ORG_ADMIN, USER_ROLE.SUPER_ADMIN
];