import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useSmartService } from '../services/SmartService';
import LoadingButton from '@mui/lab/LoadingButton';
import { SmartAlert } from '../components/SmartAlert';
import { useForm } from 'react-hook-form';
import { EMAIL_REGEXP } from '../Constants';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { PageLogo } from '../components/PageLogo';

const STATUS = {
  NEW: 0,
  SUBMITTING: 1,
  SUCCESS: 2,
  ERROR: 3,
};

export function ContactSupportScreen() {
  const { createSupport } = useSmartService();
  let [status, setStatus] = React.useState(null);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
  } = useForm({ mode: 'onChange' });

  const onSubmit = async data => {
    try {
      setStatus({ code: STATUS.SUBMITTING, message: null });
      const token = await executeRecaptcha();
      await createSupport(data.email, data.subject, data.content, token);
      setStatus({ code: STATUS.SUCCESS, message: 'Your request has been successfully sent to our support team. We will reply as soon as possible (usually within 24 - 48 hours).' });
    } catch (e) {
      console.log(e);
      setStatus({ code: STATUS.ERROR, message: 'A problem was encountered while processing your request.' });
    }
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <PageLogo size="small" mb={5} />
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={5} sm={8} xs={11} component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {(status?.code === STATUS.SUCCESS) && (
              <SmartAlert
                type="success"
                title="Success"
                message={status.message}
                closeable={false}
              />
            )}
            {status?.code !== STATUS.SUCCESS && (
              <>
                {status?.code === STATUS.ERROR && (
                  <SmartAlert
                    type="error"
                    title="Error"
                    message={status.message}
                    onClose={() => setStatus(null)}
                    closeable={true}
                    style={{mb: 2}}
                  />
                )}
                <Grid
                  container
                  spacing={0}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box>
                    <Avatar sx={{ ml: 'auto', mr: 'auto', mb: 1, bgcolor: 'secondary.main' }}>
                      <ContactSupportIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                      Contact Support
                    </Typography>
                  </Box>
                </Grid>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  {...register('email', {
                    required: { value: true, message: 'Field is required' },
                    pattern: {
                      value: EMAIL_REGEXP,
                      message: 'Please enter a valid email',
                    },
                  })}
                  error={errors.email ? true : false}
                  helperText={errors?.email?.message}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="subject"
                  label="Subject"
                  name="subject"
                  {...register('subject', {
                    required: { value: true, message: 'Field is required' },
                  })}
                  error={errors.subject ? true : false}
                  helperText={errors?.subject?.message}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  minRows="5"
                  id="content"
                  label="Content"
                  name="content"
                  {...register('content', {
                    required: { value: true, message: 'Field is required' },
                  })}
                  error={errors.content ? true : false}
                  helperText={errors?.content?.message}
                />
                {status?.code === STATUS.SUBMITTING ? (
                  <LoadingButton
                    loading
                    fullWidth
                    variant='outlined'
                    loadingPosition='start'
                    sx={{ mt: 3, mb: 2 }}
                  >
                    Submitting...
                  </LoadingButton>
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    onClick={handleSubmit(onSubmit)}
                    disabled={!formState.isValid}
                  >
                    Submit
                  </Button>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}