import * as React from 'react';
import './App.css';
import { HashRouter } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Routes, Route } from 'react-router-dom';
import { HomeScreen } from './screens/HomeScreen';
import { ForgotPasswordScreen } from './screens/ForgotPasswordScreen';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ResetPasswordScreen } from './screens/ResetPasswordScreen';
import { ContactSupportScreen } from './screens/ContactSupportScreen';
import { ContactSupportWrapperScreen } from './screens/ContactSupportWrapperScreen';

const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  return <RouterLink ref={ref} to={href} {...other} />;
});

LinkBehavior.propTypes = {
  href: PropTypes.oneOfType([
    PropTypes.shape({
      hash: PropTypes.string,
      pathname: PropTypes.string,
      search: PropTypes.string,
    }),
    PropTypes.string,
  ]).isRequired,
};

const smartTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
    },
  },
});

export const RootContext = React.createContext();

function App() {
  const rootContext = {
    state: {},
  };

  return (
    <HashRouter>
      <ThemeProvider theme={smartTheme}>
        <RootContext.Provider value={rootContext}>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/contact-support" element={<ContactSupportWrapperScreen />} />
            <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
            <Route path="/reset-password" element={<ResetPasswordScreen />} />
          </Routes>
        </RootContext.Provider>
      </ThemeProvider>
    </HashRouter>
  );
}

export default App;
