import { API_BASE_URL } from '../Constants';
import { ServiceError } from './ServiceError';

export function useSmartService() {
  const createSupport = async (email, subject, content, token) => {
    console.log('(remote) creating support request = ' + API_BASE_URL);
    let response = await fetch(`${API_BASE_URL}supports`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        subject: subject,
        content: content,
        token: token,
      }),
    });
    console.log('response = ' + JSON.stringify(response));
    if (!response.ok) {
      throw new Error('Support request failed.');
    }
  };

  const createPasswordReset = async (email) => {
    console.log('(remote) creating password reset');
    let response = await fetch(`${API_BASE_URL}users/password-reset`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
      }),
    });
    if (!response.ok) {
      throw new Error('Data fetch failed.');
    }
  };

  const completePasswordReset = async (code, password) => {
    console.log('(remote) completing password reset');
    let response = await fetch(`${API_BASE_URL}users/password`, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code: code,
        password: password,
      }),
    });
    if (!response.ok) {
      if (response.status === 404) {
        throw new ServiceError(404, 'Data fetch failed.');
      } else {
        throw new ServiceError(-1, 'Data fetch failed.');
      }
    }
  };
  
  return {
    createSupport,
    createPasswordReset,
    completePasswordReset,
  };
}
