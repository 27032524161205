import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSmartService } from '../services/SmartService';
import LoadingButton from '@mui/lab/LoadingButton';
import { SmartAlert } from '../components/SmartAlert';
import { useForm } from 'react-hook-form';
import { useQuery } from '../hooks/UseQuery';
import { PageLogo } from '../components/PageLogo';

const STATUS = {
  NEW: 0,
  SUBMITTING: 1,
  SUCCESS: 2,
  ERROR: 3,
};

export function ResetPasswordScreen() {
  let query = useQuery();
  const { completePasswordReset } = useSmartService();
  let [status, setStatus] = React.useState(null);
  let code = query.get('code');

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState,
    watch,
  } = useForm({ mode: 'onChange' });

  const onSubmit = async data => {
    try {
      setStatus({ code: STATUS.SUBMITTING, message: null });
      await completePasswordReset(code, data.password);
      setStatus({ code: STATUS.SUCCESS, message: 'Your password has been successfully reset. You may sign in now.' });
    } catch (e) {
      console.log(e);
      let message = e.code === 404 ? 
          'The password reset code specified has already been used.'
          : 'A problem was encountered while processing your request.';
      setStatus({ code: STATUS.ERROR, message: message });
    }
  };

  return (
    <Container component="main">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <PageLogo size="small" mb={5} />
        <Grid
          container
          spacing={0}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item md={5} sm={8} xs={11} component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            {!code ? (
              <SmartAlert
                type={'error'}
                title={'Error'}
                message={'The password reset code could not be found. Please try again later.'}
                closeable={false}
              />
            ) : (
              <>
                {(status?.code === STATUS.SUCCESS) && (
                  <SmartAlert
                    type="success"
                    title="Success"
                    message={status.message}
                    closeable={false}
                  />
                )}
                {status?.code !== STATUS.SUCCESS && (
                  <>
                    {status?.code === STATUS.ERROR && (
                      <SmartAlert
                        type="error"
                        title="Error"
                        message={status.message}
                        onClose={() => setStatus(null)}
                        closeable={true}
                        style={{mb: 2}}
                      />
                    )}
                    <Grid
                      container
                      spacing={0}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box>
                        <Avatar sx={{ ml: 'auto', mr: 'auto', mb: 1, bgcolor: 'secondary.main' }}>
                          <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                          Reset Password
                        </Typography>
                      </Box>
                    </Grid>
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="password"
                      label="Password"
                      name="password"
                      type="password"
                      autoComplete="password"
                      autoFocus
                      {...register('password', {
                        required: { value: true, message: 'Field is required' },
                        minLength: { value: 6, message: 'Field must be at least six characters long'},
                      })}
                      error={errors.password ? true : false}
                      helperText={errors?.password?.message}
                    />
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="confirmPassword"
                      label="Confirm Password"
                      name="confirmPassword"
                      type="password"
                      autoComplete="confirmPassword"
                      {...register('confirmPassword', {
                        required: { value: true, message: 'Field is required' },
                        validate: (val) => {
                          if (watch('password') !== val) {
                            return 'Passwords do not match';
                          }
                        },
                      })}
                      error={errors.confirmPassword ? true : false}
                      helperText={errors?.confirmPassword?.message}
                    />
                    {status?.code === STATUS.SUBMITTING ? (
                      <LoadingButton
                        loading
                        fullWidth
                        variant='outlined'
                        loadingPosition='start'
                        sx={{ mt: 3, mb: 2 }}
                      >
                        Submitting...
                      </LoadingButton>
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={handleSubmit(onSubmit)}
                        disabled={!formState.isValid}
                      >
                        Submit
                      </Button>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}